<template>
  <div>
       <div class="setting-box">
      <el-form
        v-model="sms"
        ref="form"
        label-width="80px"
      >
        <h3>阿里大鱼短信</h3>
        <el-form-item
        prop="appid" label="AccessId">
          <el-input
          type="text"
           v-model.trim="sms.body.appid"></el-input>
        </el-form-item>
        <el-form-item prop="secret" label="Secret">
          <el-input
          type="text"
           v-model.trim="sms.body.secret"></el-input>
        </el-form-item>
        <el-form-item label="登录签名名称">
          <el-input
          type="text"
           v-model.trim="sms.body.SignName"></el-input>
        </el-form-item>
         <el-form-item label="登录模板CODE">
          <el-input
          type="text"
           v-model.trim="sms.body.TemplateCode"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
             :loading="smsLoading"
             @click="smsFormSubmit"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'
export default {
  name: 'Sms',
  props: [''],
  data () {
    return {
      smsLoading: false,
      sms: {
        type: 8,
        body: {
          appid: '',
          secret: ''
          // TemplateCode: '',
          // SignName: ''
        }
      }
    }
  },
  methods: {
    smsFormSubmit (e) {
      this.editCreate(this.sms)
    },

    async editCreate (param, success, callback) {
      const { data } = await flatry(SettingService.edit(param))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/sms' })
      }
    }
  },
  async mounted () {
    const { data } = await flatry(SettingService.edit())
    if (data.data.sms) {
      this.sms = data.data.sms
    }
  }

}

</script>
<style lang='scss' scoped>
.setting-box {
    margin-bottom: 50px;
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
    h3 {
        margin-left: 80px;
        font-size: 1.06em;
    }
}
</style>
